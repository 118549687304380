/* 万擎云、全景拍摄服务、智慧文旅、智慧教育、招商加盟、AR创意营销-首页
AR创意营销-案例、AR创意营销-解决方案、大客户定制营销-案例、AI学科、h5AR创意营销
万目云h5-VR全景网站、h5 大客户定制*/
import textData from './textData'
// 万擎云
const WanqingCloud = {
    cardList:[
        {
          title:'图形化界面',
          src: "https://h5.ophyer.cn/official_website/other/WanqingCloud-1.png",
          content:'万擎云提供图形化的编辑页面，让3D场景搭建、炫酷动画的制作变得简单'
        },
        {
          title:'低代码脚本',
          src: "https://h5.ophyer.cn/official_website/other/WanqingCloud-2.png",
          content:'简单易用的预制接口，让用户通过少量代码即可写出优雅的脚本'
        },
        {
          title:'跨平台 多终端',
          src: "https://h5.ophyer.cn/official_website/other/WanqingCloud-3.png",
          content:'支持安卓、IOS、H5小程序等多渠道同时发布，确保触达更多用户场景'
        }
    ],
    productList:[
        {title:'XX小区样板间',src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",content:'XX小区新房销售'},
        {title:'汽车展厅',src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",content:'汽车线上展览会'},
        {title:'全名跳一跳',src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",content:'XX游戏'},
        {title:'3D弹球',src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",content:'XX游戏'}
    ],
    items:[
      {
        tab:'3D场景搭建',
        title:'快速搭建3D场景',
        content:['平台预设的3D模型，搭配用户上传的贴图可以快速搭建业务场景\n所需要的3D场景'],
        src:"https://h5.ophyer.cn/official_website/other/develop5-1new.png"
      },
      {
        tab:'动画制作',
        title:'序列帧、粒子动画快速生成',
        content:['序列帧动画编辑工具可以快速制作序列帧动画','内置粒子工具轻松制作粒子动画特效，让动画效果更上一层楼'],
        src:"https://h5.ophyer.cn/official_website/other/WanqingClou-5-bg.png"
      },
      {
        tab:'脚本自定义',
        title:'预设API，场景表现更随心',
        content:['通过预设API控制场景元素运动及动画的播放，让场景的表现更随心'],
        src:'https://h5.ophyer.cn/official_website/other/WanqingClou-6-new.png'
      },
    ]
}
// 全景拍摄服务
const ShootingService = {
    items: [
        {
          tab: "VR拍摄",
          title: "金牌摄影师专业拍摄",
          content:"国内知名摄影师提供一对一上门服务，高效率、专业级拍摄，完美还原真实场景",
          src:'https://h5.ophyer.cn/official_website/other/ShootingService-1.png'
        },
        {
          tab: "设备租赁",
          title: "3C大片级专业设备",
          content:
            "国产、进口专业设备租赁，提供VR全景拍摄、视频拍摄、照片拍摄、3D环物拍摄",
          src:'https://h5.ophyer.cn/official_website/other/ShootingService-2.png'
        },
        {
          tab: "全景制作",
          title: "专业技术团队打造真实全景",
          content:
            "行业内10年深耕技术团队一对一定制化开发，致力于打造一比一真实场景",
          src:'https://h5.ophyer.cn/official_website/other/ShootingService-3.png'
        },
        {
          tab: "营销内容策划",
          title: "助力企业扩展营销方式",
          content:
            "十二年品牌营销经验资深营销专家为企业提供定制化VR全景营销策略，助力企业营销方式拓展",
          src:'https://h5.ophyer.cn/official_website/other/ShootingService-4.png'
        },
    ],
    cardList: [
        {
          title: "专业团队",
          content:
            "深耕行业10年，更了解您的需求，5年以上顾问经验提供7*24小时专属服务，为您解决所有拍摄问题",
          src: require('../../public/img/icons/ShootingService/ShootingService-1.png'),
        },
        {
          title: "高端设备",
          content:
            "涵盖所有型号国内设备及进口设备，为您提供全方位的拍摄服务与制作服务",
          src: require('../../public/img/icons/ShootingService/ShootingService-2.png'),
        },
        {
          title: "丰富的运营经验",
          content:
            "掌中飞天对线上、线下市场有着深度的了解和丰富的运营经验，并与头部渠道有着深度战略合作关系，协助品牌快速精准落地营销策略",
          src: require('../../public/img/icons/ShootingService/ShootingService-3.png'),
        },
        {
          title: "企业信赖",
          content: "深耕行业10年，众多合作伙伴，他们得选择源于对我们的信赖",
          src: require('../../public/img/icons/ShootingService/ShootingService-4.png'),
        },
    ],
    peitaoList:[
          { title: '导播间', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-1.png'},
          { title: '休息区', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-2.png'},
          { title: '会议室', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-3.png'},
          { title: '化妆间', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-4.png'},    
          { title: '摄像', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-5.png'},
          { title: '设计策划', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-6.png'},
          { title: '演员灯光', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-7.png'},
          { title: '影视后期', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-8.png'},    
    ],
    cooperation:require('../../public/img/icons/ShootingService/cooperation.png'),
}
// 智慧文旅
const IntelligentCulturalTourism = {
    cards: [
        {
          title: "智慧景区",
          src: "https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png",
          content:"通过AR/VR技术帮助城市实现智慧化产业升级转型。“一部手机游景区”，宣告掌中飞天具备智慧旅游、数字服务及大数据处理能力",
        },
        {
          title: "智慧展馆",
          src: "https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-2.png",
          content: "结合地方特色，为城市定制独具特色的科技文化展馆。实现文化与科技一体化沉浸展示，打造现象级营销案例",
        },
        {
          title: "虚拟展厅",
          src: "https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-3.png",
          content: "借助文化IP、结合客户需求，让用户享受科技带来的沉浸式展览体验，打造爆款级科技虚拟展厅",
        },
        {
          title: "VR景区体验",
          src: "https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-4.png",
          content: "为景区打造专属VR看景功能。通过移动端设备即可浏览不同景点的3D景象。帮助游客多角度、近距离观赏景点景象。",
        },
    ]
}
// 智慧教育
const WisdomEducation = {
    sheetList: [
        {
          title: "强大的交互性",
          content:
            "让学生更好的参与知识情景中，每个知识点形成双向交互，让孩子学习知识的过程有参与感",
          src: require("../../public/img/icons/WisdomEducation/WisdomEducation-1.png"),
        },
        {
          title: "趣味教学提高孩子自主能动性",
          content:
            "将知识点获取变为趣味小游戏，利用孩子好奇心里让孩子自主学习并加深记忆",
          src: require("../../public/img/icons/WisdomEducation/WisdomEducation-2.png"),
        },
        {
          title: "真实情景教学",
          content:
            "摒弃传统的视频+图片形式，利用全景教学让孩子融入真实的场景中提高孩子记忆力",
          src:require("../../public/img/icons/WisdomEducation/WisdomEducation-3.png"),
        },
    ],
    itemList:[
        {
              title:'中小学科学、天文地理生物',
              content:'利用全景模拟天文地理景色、景象，免去图片、视频等传统方式来传授的知识，现在直接呈现在孩子们的眼前，让他们切身体会',
              src:'https://h5.ophyer.cn/official_website/other/WisdomEducation-1.png'
          },
          {
              title:'VR线上研学课',
              content:'包含名人故居，历史文物、建筑，纪念展馆等220个基地，将教研内容与全景结合，互动式体验，提升教研效果',
              src:'https://h5.ophyer.cn/official_website/other/WisdomEducation-2.png'
          },
          {
              title:'爱国教育引起学生共鸣',
              content:'VR实景下，带学生真实走过长征路，体验前辈先人的爬雪山、过草地、大渡河，带学生再次走进那段艰苦的岁月，体验抗日战争胜利的艰辛，唤起学生共鸣，激发学生爱国主义精神',
              src:'https://h5.ophyer.cn/official_website/other/WisdomEducation-3.png'
          },
          {
              title:'虚拟仿真实训室建设',
              content:'针对不同的行业、专业提供数字化仿真训练室，涵盖汽修、化工、旅游、电力、护理等专业，降低培训成本，提高教学质量',
              src:'https://h5.ophyer.cn/official_website/other/WisdomEducation-4.png'
          }
          
    ]
}
// 招商加盟
const InvestmentPromotion = {
    items: [
        {
          tab: "高额收益",
          src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-1.png'),
          content:'高额销售返利，新签客户、老客户续约、定制化服务',
        },
        {
          tab: "全面培训",
          src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-2.png'),
          content:'产品、市场、营销全面无死角培训，帮助合作伙伴最短时间内了解行业',
        },
        {
          tab: "强力扶持",
          src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-3.png'),
          content:'渠道经理驻地支持，帮助合作伙伴快速开拓市场',
        },
        {
          tab: "销售工具",
          src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-4.png'),
          content:'提供线上线下全面销售工具，帮助合作伙伴提高效率',
        },
        {
          tab: "推广物料",
          src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-5.png'),
          content:'提供合作伙伴市场推广时所用的营销物料',
        },
        {
          tab: "服务支撑",
          src: require('../../public/img/icons/InvestmentPromotion/InvestmentPromotion-6.png'),
          content:'专属客户服务，帮助合作伙伴解决产品、技术等问题',
        },
    ],
    advantage: [
        {
          tab: "市场前景广阔",
          src: 'https://h5.ophyer.cn/official_website/other/InvestmentPromotion-1.png',
          titleList: [
            {
              title: "政策扶持",
              content:
                "近年来国家不断推出政策、发展基金等助推产业发展；国家十四五规划将AR/VR技术正式写入数字经济重点产业领域",
            },
            {
              title: "5G+时代",
              content:
                "5G时代来临，信息网络更加快速、稳定成熟，为AR/VR的发展提供了良好的契机",
            },
            {
              title: "产业聚集",
              content:
                "随着VR技术的更迭，VR产业逐渐形成规模化、应用化，市场需求量骤增",
            },
          ],
          iconImg:require("@/assets/images/InvestmentPromotion/marketProspect.png")
        },
        {
          tab: "稳定的技术支持",
          src: 'https://h5.ophyer.cn/official_website/other/InvestmentPromotion-2.png',
          titleList: [
            {
              title: "技术基础坚实",
              content:
                "10年技术沉淀，深耕AR/VR、AI及营销领域，在国内的AR/VR智慧营销领域名列前茅",
            },
            {
              title: "全栈自研技术",
              content: "独立自主研发AR/VR引擎 ｜ 累计获得软件著作权234个，授权专利4个，作品著作权21个",
            },
            {
              title: "稳定的开发团队",
              content:
                "100+技术团队强有力支撑，团队成员稳定，为系统开发升级保驾护航",
            },
          ],
          iconImg:require("@/assets/images/InvestmentPromotion/technology.png")
        },
        {
          tab: "全面服务保障",
          src: 'https://h5.ophyer.cn/official_website/other/InvestmentPromotion-3.png',
          titleList: [
            {
              title:"多渠道在线客服",
              content:"7x24小时应急响应，只要您需要，我们随时在身边",
            },
            {
              title:"顾问式运营服务",
              content:"项目全链路追踪，全程指导，为营销活动持续助力",
            },
            {
              title: "智能数据分析",
              content:
                "营销活动效果监控，实时追踪活动数据，为营销方案调整提供数据支持",
            },
          ],
          iconImg:require("@/assets/images/InvestmentPromotion/service.png")
        },
    ],
}
//AR创意营销-首页
const ARHome = {
  'modular':{
    title:'AR互动游戏',
    titleBg:'AR INTERACTIVE GAME',
    subtitle:'海量游戏模板+多种营销玩法，助力商家营销转化'
  },
  advantage: [
      {
        tab: "海量模板一键套用",
        src: 'https://h5.ophyer.cn/official_website/other/ARhome1-4.png',
        titleList:{
          title: "分场景、分目标，\n满足多种需求的营销玩法",
          content:
            "拉新、引流、促活、促转化，轻松制作即可生成符合自己营销需求的趣味小游戏",
        },
        titleBtn: ['· 促进活跃','· 获客拉新','· 节日营销','· 品牌宣传'],
        iconImg:require("@/assets/images/InvestmentPromotion/marketProspect.png")
      },
      {
        tab: "趣味AR识别",
        src: 'https://h5.ophyer.cn/official_website/other/ARhome1-2.png',
        titleList:{
          title: "多种玩法的AR识别方式\n全方位调动用户参与性",
          content:
            "图像识别、物体识别、人脸识别、陀螺仪、重力感应，更多趣味玩法持续迭代中…",
        },
        titleBtn: ['· 图像识别','· 人脸识别','· 陀螺仪','· 重力感应'],
        iconImg:require("@/assets/images/InvestmentPromotion/technology.png")
      },
      {
        tab: "多种奖励玩法",
        src: 'https://h5.ophyer.cn/official_website/other/ARhome1-3-1.png',
        titleList:{
          title:"转盘、抽奖、分层奖励等多种奖励玩法，满足更多营销场景",
          content:"AR互动游戏结合实物、卡券/卡密、积分等多种奖励方式，游戏好玩又有趣，有效刺激用户自传播",
        },
        titleBtn: ['· 转盘抽奖','· 红包奖励','· 游戏得分','· 游戏时长'],
        iconImg:require("@/assets/images/InvestmentPromotion/service.png")
      },
      {
        tab: "权益分层派发",
        src: 'https://h5.ophyer.cn/official_website/other/ARhome1-1-1.png',
        titleList:{
          title:"权益分层派发，引导玩家持续互动\n提升游戏曝光率",
          content:"按游戏时长，得分，排名三种方式分层派发奖励，玩的越好越有可能赢取更好的奖励，持续刺激玩家积极参与",
        },
        titleBtn: ['· 游戏得分','· 游戏时长','· 游戏排名'],
        iconImg:require("@/assets/images/InvestmentPromotion/service.png")
      },
  ],
  itemList:[
      {
            title:'模板编辑方式\n轻松生成趣味互动内容',
            content:'简单操作即可完成内容制作，降低使用门槛',
            src:'https://h5.ophyer.cn/official_website/other/ARhome2-1-1.png'
        },
        {
            title:'游戏界面灵活配置\n提升品牌影响力',
            content:'界面元素及内容均支持自定义，让用户在参与游戏的同时加深品牌印象',
            src:'https://h5.ophyer.cn/official_website/other/ARhome2-2.png'
        }
        
  ],
  'case':{
    title:'案例精选',
    titleBg:'CASE SELECTION',
    subtitle:'强互动 重效果 助力商家提升营销效果',
    anli:[textData.anli.momojiang,textData.anli.weida,textData.anli.mingshengyaoye,textData.anli.delongkafeiji]
  },
  'ARadvantage':{
    title:'我们的优势',
    titleBg:'ADVANTAGE',
    subtitle:'强互动 重效果 助力商家提升营销效果',
    ProductCard:[
      {img:'https://h5.ophyer.cn/official_website/other/IPhy31.png',icon:require('../../public/img/icons/AR/ARhome4-1.png'),title:'趣味互动',content:'AR趣味互动，打造营销玩法新体验；结合多种权益奖励，助力营销指标'},
      {img:'https://h5.ophyer.cn/official_website/other/IPhy32.png',icon:require('../../public/img/icons/AR/ARhome4-2.png'),title:'海量模板',content:'丰富的营销模板，简单操作即可完成自主创意的AR小游戏，降低使用门槛'},
      {img:'https://h5.ophyer.cn/official_website/other/IPhy33.png',icon:require('../../public/img/icons/AR/ARhome4-3.png'),title:'组件丰富',content:'抽奖，转盘、娃娃机、红包等热门营销插件，提升活跃与销售转化'},
      {img:'https://h5.ophyer.cn/official_website/other/IPhy34.png',icon:require('../../public/img/icons/AR/ARhome4-4.png'),title:'技术保障',content:'100+资深工程师7*24小时在线，全天候服务，保障活动稳定'}
    ],
  },
}
// AR创意营销-案例
const ARcase = [
  {
    title: '精选案例',
    tabItem: [
      textData.anli.haoshixian,
      textData.anli.haerbingpijiu,
      textData.anli.delongkafeiji,
      textData.anli.canlangang,
      textData.anli.ardiqiuyi,
      textData.anli.aojiabaohuyan,
      textData.anli.dongchengjieneng,
      textData.anli.shuijingfang,
      textData.anli.zhongxuediqiuyi,
      textData.anli.feitianyundong,
      textData.anli.wangshangchufang,
      textData.anli.yingteermanghe,
      textData.anli.leiyoushanghai,
      textData.anli.wanjuchezhan,
      textData.anli.kunyuanyimeng,
      textData.anli.yuanliangxiaochuan,
      textData.anli.mengniu,
      textData.anli.haixingkongtiao,
      textData.anli.feixingsheji,
      textData.anli.sitongbanjia,
      textData.anli.gedou,
      textData.anli.dingshengdichang,
      textData.anli.qiandudu,
      textData.anli.costa,
      textData.anli.zhongxing,
      textData.anli.baohulu,
      textData.anli.qinghuaxiaoyuan,
      textData.anli.taiqiu,
      textData.anli.jiamianfandian,
      textData.anli.dahuaxiyoutg,
      textData.anli.dahuaxiyouwutai,
      textData.anli.bowuguan,
      textData.anli.arbaikeditu,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.paizhaoshibie,
      textData.anli.jingqudaka,
      textData.anli.jiadian,
    ]
  },
  {
    title: '电商营销',
    tabItem: [
      textData.anli.delongkafeiji,
      textData.anli.feitianyundong,
      textData.anli.haerbingpijiu,
      textData.anli.haoshixian,
      textData.anli.yingteermanghe,
      textData.anli.aojiabaohuyan,
      textData.anli.leiyoushanghai,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.canlangang,
      textData.anli.shuijingfang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.haixingkongtiao,
      textData.anli.costa,
      textData.anli.zhongxing,
      textData.anli.baohulu,
      textData.anli.wanjuchezhan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.jiadian,
    ]
  },
  {
    title: '餐饮服务',
    tabItem: [
      textData.anli.haoshixian,
      textData.anli.aojiabaohuyan,
      textData.anli.haerbingpijiu,
      textData.anli.canlangang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.costa,
    ]
  },
  {
    title: '智慧教育',
    tabItem: [
      textData.anli.zhongxing,
      textData.anli.kunyuanyimeng,
      textData.anli.zhongxuediqiuyi,
      textData.anli.arbaikeditu
    ]
  },
  {
    title: '智慧文旅',
    tabItem: [
      textData.anli.leiyoushanghai,
      textData.anli.yuanliangxiaochuan,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.bowuguan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.paizhaoshibie,
      textData.anli.dahuaxiyoutg,
      textData.anli.jingqudaka
    ]
  },
  {
    title: '房产家居',
    tabItem: [
      textData.anli.haixingkongtiao,
      textData.anli.dingshengdichang,
      textData.anli.jiadian
    ]
  },
  {
    title: '公共服务',
    tabItem: [
      textData.anli.dongchengjieneng,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.sitongbanjia,
    ]
  },
  {
    title: '游戏',
    tabItem: [
      textData.anli.feitianyundong,
      textData.anli.gedou,
      textData.anli.mengniu,
      textData.anli.feixingsheji,
      textData.anli.taiqiu,
      textData.anli.dahuaxiyoutg,
    ]
  },
]
// AR创意营销-解决方案
const ARSolution = {
  modular:{
    title:'结合营销目标，提供高效解决方案',
    titleBg:'未完待续',
    subtitle:'基于多年技术沉淀 驱动交互创新，实现业务增长',
    items:[
      {
        tab:'品牌宣传',
        title:'AR识别品牌LOGO加深品牌印象',
        content:'识别图、游戏元素、结算页面等均可添加品牌LOGO，提高品牌曝光度，加深印象',
        src:"https://h5.ophyer.cn/official_website/other/WanqingClou-4-bg.png"
      },
      {
        tab:'线下引流获客',
        title:'丰富玩法结合礼包、门店券、礼品卡为门店强势引流',
        content:'分层、抽奖、集卡等丰富的营销组件，让用户在游戏的过程中获得奖励，引导线下核销',
        src:"https://h5.ophyer.cn/official_website/other/WanqingClou-5-bg.png"
      },
      {
        tab:'线上产品促销',
        title:'优惠券、积分等权益奖励有效促进’上转化，提升GMV',
        content:'通过发放优惠券、积分以及直接跳转上线门店，有效拉客单，促进用户消费',
        src:'https://h5.ophyer.cn/official_website/other/WanqingClou-6-new.png'
      },
      {
        tab:'公众号吸粉',
        title:'通过授权公众号，有效吸粉养粉构建私域流量',
        content:'用户可授权自主公众号，将公众号二维码展示在游戏界面，为公众号吸粉',
        src:"https://h5.ophyer.cn/official_website/other/WanqingClou-5-bg.png"
      },
      {
        tab:'裂变增长',
        title:'分享复活及朋友圈海报有效促进活动',
        content:'用户可授权自主公众号，将公众号二维码展示在游戏界面，为公众号吸粉',
        src:'https://h5.ophyer.cn/official_website/other/WanqingClou-6-new.png'
      },
    ]
  },
  ARAbility:{
    title:'丰富的AR能力',
    titleBg:'AR ABILITY',
    subtitle:'图像、物体、面部等多种识别方式 互动体验更强',
    items:[
      {
        title:'图形识别',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title:'物体识别',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title:'人脸识别',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title:'陀螺仪追踪',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title:'到店扫码',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title:'3D动画',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title:'透明Webivew',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title:'贴片动画',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      }
    ]
  },
  entrance:{
    title:'活动入口更灵活 为营销蓄能',
    titleBg:'ACTIVITY ENTRANCE',
    subtitle:'微信、APP、web等多种打开方式 促进活动传播',
    items:[
      {
        title:'资源位/游戏专题',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title:'公众号菜单跳转',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title:'微信扫图识别',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
      {
        title:'朋友圈海报分享',
        src:'https://h5.ophyer.cn/official_website/other/IntelligentCulturalTourism-1.png'
      },
    ]
  }
}
//大客户定制营销-解决方案
const customersSolution = {
  itemList:[
    {
          title:'线下门店',
          content:'引流新客到店，提高老带新能力，提高原有客单价，促进成交',
          src:'https://h5.ophyer.cn/official_website/other/cus01.png'
      },
      {
          title:'电商场景',
          content:'促进拉新，提高用户活跃，引导用户关注公司公众号，为私域流量构建持续蓄力',
          src:'https://h5.ophyer.cn/official_website/other/cus02.png'
      },
      {
          title:'微信生态场景',
          content:'VR实景下，带学生真实走过长征路，体验前辈先人的爬雪山、过草地、大渡河，带学生再次走进那段艰苦的岁月，体验抗日战争胜利的艰辛，唤起学生共鸣，激发学生爱国主义精神',
          src:'https://h5.ophyer.cn/official_website/other/cus03.png'
      },
      {
          title:'APP应用场景',
          content:'API对接实现各类权益体系互通，助力用户长线运营',
          src:'https://h5.ophyer.cn/official_website/other/cus04.png'
      },
      {
          title:'匹配品牌风格',
          content:'专业一对一定制，根据客户风格定制专属风格AR、VR项目，提供专业化行业解决方案',
          src:'https://h5.ophyer.cn/official_website/other/cus05.png'
      }
      
]
}
// 大客户定制营销-案例
const customersCase = [
  {
    title: '精选案例',
    tabItem: [
      textData.anli.feitianyundong,
      textData.anli.haerbingpijiu,
      textData.anli.haoshixian,
      textData.anli.delongkafeiji,
      textData.anli.yingteermanghe,
      textData.anli.aojiabaohuyan,
      textData.anli.leiyoushanghai,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.canlangang,
      textData.anli.shuijingfang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.haixingkongtiao,
      textData.anli.costa,
      textData.anli.zhongxing,
      textData.anli.baohulu,
      textData.anli.wanjuchezhan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.jiadian,
      textData.anli.weida,
      textData.anli.mingshengyaoye,
      textData.anli.kidsyundian,
      textData.anli.nike_vr,
      textData.anli.dafeixing,
      textData.anli.sanzhisongshu_jd_tm,
      textData.anli.baicaiershouche,
      textData.anli.wanshifujiaju,
      textData.anli.quanjingjiushui,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.gaoerfu,
      textData.anli.haiyangguan,

    ]
  },
  {
    title: '电商营销',
    tabItem: [
      textData.anli.feitianyundong,
      textData.anli.haerbingpijiu,
      textData.anli.haoshixian,
      textData.anli.delongkafeiji,
      textData.anli.yingteermanghe,
      textData.anli.aojiabaohuyan,
      textData.anli.leiyoushanghai,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.canlangang,
      textData.anli.shuijingfang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.haixingkongtiao,
      textData.anli.costa,
      textData.anli.zhongxing,
      textData.anli.baohulu,
      textData.anli.wanjuchezhan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.jiadian,
      textData.anli.weida,
      textData.anli.mingshengyaoye,
      textData.anli.kidsyundian,
      textData.anli.nike_vr,
      textData.anli.dafeixing,
      textData.anli.sanzhisongshu_jd_tm,
      textData.anli.baicaiershouche,
      textData.anli.wanshifujiaju,
      textData.anli.quanjingjiushui,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.gaoerfu,
      textData.anli.haiyangguan,
      textData.anli.haoshixian,
      textData.anli.aojiabaohuyan,
      textData.anli.haerbingpijiu,
      textData.anli.canlangang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.costa,
      textData.anli.zhongxing,
      textData.anli.kunyuanyimeng,
      textData.anli.zhongxuediqiuyi,
      textData.anli.arbaikeditu,
      textData.anli.ardiqiuyi,
      textData.anli.wodenongchang,
      textData.anli.shengbimaliang,
      textData.anli.qicezhuangpei,
      textData.anli.xiaofangrenyuan,
      textData.anli.zhongxuedili,
      textData.anli.lajifenlei,
      textData.anli.meilizhongguo,
      textData.anli.hongcunjiyi,
      textData.anli.qiaojiadayuan,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.youlun,
      textData.anli.haiyangguan,
      textData.anli.leiyoushanghai,
      textData.anli.yuanliangxiaochuan,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.bowuguan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.paizhaoshibie,
      textData.anli.dahuaxiyoutg,
      textData.anli.jingqudaka,
      textData.anli.meilizhongguo,
      textData.anli.hongcunjiyi,
      textData.anli.qiaojiadayuan,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.youlun,
      textData.anli.haiyangguan,
      textData.anli.haixingkongtiao,
      textData.anli.dingshengdichang,
      textData.anli.jiadian,
      textData.anli.dongchengjieneng,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.sitongbanjia,
      textData.anli.wodenongchang,
      textData.anli.miaojiankang,
      textData.anli.xiaofangrenyuan,
      textData.anli.liantong,
      textData.anli.lajifenlei,
      // textData.anli.baicaiershouche,
      // textData.anli.gaoerfu,
      // textData.anli.fengcheyangche,
      // textData.anli.saiche,
      // textData.anli.quanjingqiche,
      // textData.anli.qicezhuangpei,
      textData.anli.feitianyundong,
      textData.anli.gedou,
      textData.anli.mengniu,
      textData.anli.feixingsheji,
      textData.anli.taiqiu,
      textData.anli.dahuaxiyoutg,
      textData.anli.kidsyundian,
      textData.anli.dafeixing,
      textData.anli.saiche,
      textData.anli.shengbimaliang,
      textData.anli.liantong,
    ]
  },
  {
    title: '餐饮服务',
    tabItem: [
      textData.anli.haoshixian,
      textData.anli.aojiabaohuyan,
      textData.anli.haerbingpijiu,
      textData.anli.canlangang,
      textData.anli.mengniu,
      textData.anli.wangshangchufang,
      textData.anli.costa,
    ]
  },
  {
    title: '智慧教育',
    tabItem: [
      textData.anli.zhongxing,
      textData.anli.kunyuanyimeng,
      textData.anli.zhongxuediqiuyi,
      textData.anli.arbaikeditu,
      textData.anli.ardiqiuyi,
      textData.anli.wodenongchang,
      textData.anli.shengbimaliang,
      textData.anli.qicezhuangpei,
      textData.anli.xiaofangrenyuan,
      textData.anli.zhongxuedili,
      textData.anli.lajifenlei,
    ]
  },
  {
    title: '智慧文旅',
    tabItem: [
      textData.anli.meilizhongguo,
      textData.anli.hongcunjiyi,
      textData.anli.qiaojiadayuan,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.youlun,
      textData.anli.haiyangguan,
      textData.anli.leiyoushanghai,
      textData.anli.yuanliangxiaochuan,
      textData.anli.wenchuangchanpingdingzhi,
      textData.anli.bowuguan,
      textData.anli.dahuaxiyouwutai,
      textData.anli.paizhaoshibie,
      textData.anli.dahuaxiyoutg,
      textData.anli.jingqudaka,
      textData.anli.meilizhongguo,
      textData.anli.hongcunjiyi,
      textData.anli.qiaojiadayuan,
      textData.anli.vrquanjingyoushanghai,
      textData.anli.youlun,
      textData.anli.haiyangguan,
    ]
  },
  {
    title: '房产家居',
    tabItem: [
      textData.anli.haixingkongtiao,
      textData.anli.dingshengdichang,
      textData.anli.jiadian
    ]
  },
  {
    title: '公共服务',
    tabItem: [
      textData.anli.dongchengjieneng,
      textData.anli.bowuguan,
      textData.anli.yuanliangxiaochuan,
      textData.anli.sitongbanjia,
      textData.anli.wodenongchang,
      textData.anli.miaojiankang,
      textData.anli.xiaofangrenyuan,
      textData.anli.liantong,
      textData.anli.lajifenlei,
    ]
  },
  // {
  //   title: '汽车出行',
  //   tabItem: [
  //     textData.anli.baicaiershouche,
  //     textData.anli.gaoerfu,
  //     textData.anli.fengcheyangche,
  //     textData.anli.saiche,
  //     textData.anli.quanjingqiche,
  //     textData.anli.qicezhuangpei,
  //   ]
  // },
  {
    title: '游戏',
    tabItem: [
      textData.anli.feitianyundong,
      textData.anli.gedou,
      textData.anli.mengniu,
      textData.anli.feixingsheji,
      textData.anli.taiqiu,
      textData.anli.dahuaxiyoutg,
      textData.anli.kidsyundian,
      textData.anli.dafeixing,
      textData.anli.saiche,
      textData.anli.shengbimaliang,
      textData.anli.liantong,
    ]
  },
]
// 大客户定制营销 - 首页
const customersHome = {
  items: [
    {
      tab: "活动方案策划",
      title: "活动内容、策划方案量身定制",
      content:"结合客户营销目标与预算，灵活设计活动方案及功能，更符合您的营销诉求",
      src:'https://h5.ophyer.cn/official_website/other/saas-tab-img1.png'
    },
    {
      tab: "功能模块定制",
      title: "3C大片级产品功能定制，全方位满足客户需求专业设备",
      content:
        "支持定制开发、本地部署和API对接，致力于提供全渠道、多场景的营销服务",
      src:'https://h5.ophyer.cn/official_website/other/saas-tab-img2.png'
    },
    {
      tab: "专属运营",
      title: "一对一专属运营指导活动过程全透明可追溯",
      content:
        "签约后即安排一对一的大客户运营经理，全程参与活动运营，依据数据反馈和客户需求，随时调整运营方案",
      src:'https://h5.ophyer.cn/official_website/other/saas-tab-img3.png'
    },
    {
      tab: "数据复盘",
      title: "活动结束后提供数据分析报告营销效果复盘",
      content:
        "对活动结果即时复盘，对比客户预期，总结活动的内容策划、执行方案及奖品设计，支撑后续营销决策",
      src:'https://h5.ophyer.cn/official_website/other/saas-tab-img4.png'
    },
],
cardList: [
    {
      title: "产品单独定制化",
      content:
        "AR/VR趣味互动内容定制+技术方案定制、部署，全方位满足客户需求",
      src: require('../../public/img/icons/customerHome/cus2-1.svg'),
    },
    {
      title: "一对一运营指导",
      content:
        "针对品牌方，提供一对一大客户专属运营指导；服务活动全周期",
      src: require('../../public/img/icons/customerHome/cus2-2.svg'),
    },
    {
      title: "资源共享联动营销",
      content:
        "付费大客户共享资源，联动营销，360°赋能品牌影响力，树立行业标杆",
      src: require('../../public/img/icons/customerHome/cus2-3.svg'),
    },
    {
      title: "7*24管家服务",
      content: "100+资深工程师7*24小时在线，全天候保障活动稳定",
      src: require('../../public/img/icons/customerHome/cus2-4.svg'),
    },
],
peitaoList:[
      { title: '电商零售', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img1.png'},
      { title: '3C数码', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img2.png'},
      { title: '房产家居', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img3.png'},
      { title: '汽车', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img4.png'},    
      { title: '智慧文旅', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img5.png'},
      { title: '智慧教育', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img6.png'},
      { title: '酒店民宿', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img7.png'},
      { title: '餐饮', src: 'https://h5.ophyer.cn/official_website/other/saas-cj-img8.png'},    
],
}
// AI学科
const AICase = [
  {
    title: '全部分类',
    tabItem: [
      textData.anli.ziranyuyan,
      textData.anli.anfangzhongduan,
      textData.anli.zhinengjishu,
      textData.anli.xianzhihuijiaotong,
      textData.anli.zhuanjiachangtan,
      textData.anli.wanyijiama,
      textData.anli.jingyonganfang,
      textData.anli.qinghua,
      textData.anli.guangdonggaoxiao,
      textData.anli.rengongzhineng,
      textData.anli.jiangsugaoxiao,
      textData.anli.jiqixuexi,
      textData.anli.benke37,
      textData.anli.changjiansuanfa,
      textData.anli.zhihuijinrong,
      textData.anli.wugefadianli,
      textData.anli.yantaigaoxiao,
      textData.anli.renkoulaolinghua,
    ]
  },
  {
    title: 'AI与生活',
    tabItem: [
      textData.anli.anfangzhongduan,
      textData.anli.xianzhihuijiaotong,
      textData.anli.zhuanjiachangtan,
      textData.anli.wanyijiama,
      textData.anli.jingyonganfang,
      textData.anli.zhihuijinrong,
    ]
  },
  {
    title: 'AI挖掘技术',
    tabItem: [
      textData.anli.ziranyuyan,
      textData.anli.zhinengjishu,
      textData.anli.rengongzhineng,
      textData.anli.qinghua,
      textData.anli.jiqixuexi,
      textData.anli.changjiansuanfa,
    ]
  },
  {
    title: 'AI学术',
    tabItem: [
      textData.anli.wugefadianli,
      textData.anli.renkoulaolinghua,
    ]
  },
  {
    title: '高校研究院',
    tabItem: [
      textData.anli.guangdonggaoxiao,
      textData.anli.benke37,
      textData.anli.jiangsugaoxiao,
      textData.anli.yantaigaoxiao,
    ]
  },
]
// h5 AR创意营销
const h5SolutionAR = {
  bannerList:["https://h5.ophyer.cn/official_website/banner/h5Home-banner01.png","https://h5.ophyer.cn/official_website/banner/h5Home-banner02.png"],
  module:{
    title:'AR互动游戏',
    subtitle:'海量游戏模板+多种营销玩法，助力商家营销转化',
    listArr:[
      {
        tabTitle: '海量模板一键套用',
        img: 'https://h5.ophyer.cn/official_website/other/h5ARhome1.png',
        lunTitle: '分场景、分目标\n满足多种需求的营销玩法',
        tip: '拉新、引流、促活、促转化，轻松制作即可生成符合自己营销需求的趣味小游戏',
        text: []
      },
      {
        tabTitle: '趣味AR识别',
        img: 'https://h5.ophyer.cn/official_website/other/h5ARhome2.png',
        lunTitle: '多种玩法的AR识别方式\n全方位调动用户参与性',
        tip: '图像识别、物体识别、人脸识别、陀螺仪、重力感应，更多趣味玩法持续迭代中…',
        text: []
      },
      {
        tabTitle: '多种奖励玩法',
        img: 'https://h5.ophyer.cn/official_website/other/h5ARhome3_1.jpg',
        lunTitle: '转盘、抽奖、分层奖励等多种奖励\n玩法，满足更多营销场景',
        tip: 'AR互动游戏结合实物、卡券/卡密、积分等多种奖励方式，游戏好玩又有趣，有效刺激用户自传播',
        text: []
      },
      {
        tabTitle: '分享裂变属性',
        img: 'https://h5.ophyer.cn/official_website/other/h5ARhome4_1.jpg',
        lunTitle: '海报分享、复活游戏分享等多种分享\n触点，提升游戏曝光率',
        tip: 'AR互动游戏结合实物、卡券/卡密、积分等多种奖励方式，游戏好玩又有趣，有效刺激用户自传播',
        text: []
      }
    ]
  },
  module1: [
    {
      title:"模板编辑方式\n轻松生成趣味互动内容",
      icon: "https://h5.ophyer.cn/official_website/other/ARhome2-1-1.png",
      explain: "简单操作即可完成内容制作，减低使用门槛",
    },
    {
      title:"游戏界面灵活配置\n提升品牌影响力",
      icon: "https://h5.ophyer.cn/official_website/other/ARhome2-2.png",
      explain: "界面元素及内容均支持自定义，让用户在参与游戏的同时加深品牌印象",
    }
  ],
}
// h5 VR全景网站
const h5VRpanoramaWebsite = {
  module:{
    title:'VR全景系统',
    subtitle:'720°沉浸式体验，胜似“身临其境”',
    listArr:[
      {
        id:1,
        tabTitle: 'VR展厅',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img01-1.png',
        lunTitle: 'VR展厅',
        tip: '沉浸式交互体验，全面展示品牌信息',
        text: [
          '• 720°可交互全景，为您量身打造线上虚拟展厅',
          '• 热点、虚拟导购等特色功能，适配更多营销场景',
          '• 热点、嵌入、虚拟人物等特色功能，适配更多营销场景'
        ]
      },
      {
        id:2,
        tabTitle: 'VR带看',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img02-1.png',
        lunTitle: 'VR带看',
        tip: '互动演示，实现VR远程带看',
        text: [
          '• 邀请多个用户共同加入联动，远程演示更方便',
          '• 打破空间和距离，无障碍交流更高效',
          '• 语音、说一说实时互动，交流本该如此简单'
        ]
      },
      {
        id:3,
        tabTitle: '3D模型',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img03-1.png',
        lunTitle: '3D模型',
        tip: '3D场景模型，产品体验更真实',
        text: [
          '• 支持FBX、GLTF多种模型导入，适用性更强',
          '• 3D展示产品信息，交互更强，距离更近'
        ]
      },
      {
        id:4,
        tabTitle: '360环物',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-01.png',
        lunTitle: '360环物',
        tip: '贴合电商场景，让产品“动”起来',
        text: [
          '• 简单拍摄即可生成360环物',
          '• 打破传统的静态图片展示，更多细节展示',
          '• 电商、汽车、展馆等多场景适用'
        ]
      },
      {
        id:5,
        tabTitle: '虚拟导购',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img05-1.png',
        lunTitle: '虚拟导购',
        tip: '虚拟形象导购+智能语音讲解，更具亲和力',
        text: [
          '• 系统提供多款形象多款动作，适用性更强',
          '• 支持添加欢迎语，强化第一印象',
          '• 动作引导结合语音讲解，代入感更强'
        ]
      },
      {
        id:6,
        tabTitle: '电子沙盘',
        img: 'https://h5.ophyer.cn/official_website/other/vrPanorama-home-warp1-img06_1.jpg',
        lunTitle: '电子沙盘',
        tip: '全局总览，想看哪里点哪里',
        text: [
          '• 提升场景辨识度，用于路线规划和导引',
          '• 不同场景可设置不同的沙盘概览图，提升用户体验感',
          '• 可根据漫游点为沙盘添加标记，浏览场景更便捷'
        ]
      }
    ]
  },
  module1:{
    title:'VR全景系统',
    subtitle:'720°沉浸式体验，胜似“身临其境”',
    listArr:[
      {
        tabTitle: '服务介绍',
        itemList:[
          {
            title:'金牌摄影师专业拍摄',
            src:'https://h5.ophyer.cn/official_website/other/wmyH5VRpanoramaWebsite1.png',
            content:'国内知名摄影师提供一对一上门服务，高效率、专业级拍摄，完美还原真实场景'
          },
          {
            title:'3C大片级专业设备',
            src:'https://h5.ophyer.cn/official_website/other/wmyH5VRpanoramaWebsite2.png',
            content:'国产、进口专业设备租赁，提供VR全景拍摄、视频拍摄、照片拍摄、3D环物拍摄'
          },
          {
            title:'专业技术团队打造真实全景',
            src:'https://h5.ophyer.cn/official_website/other/wmyH5VRpanoramaWebsite3.png',
            content:'行业内10年深耕技术团队一对一定制化开发，致力于打造一比一真实场景'
          },
          {
            title:'助力企业扩展营销方式',
            src:'https://h5.ophyer.cn/official_website/other/wmyH5VRpanoramaWebsite4.png',
            content:'十二年资深品牌营销专家为企业提供定制化VR全景营销策略，助力企业营销方式拓展'
          }
        ]
      },
      {
        tabTitle: '服务流程',
        itemList:[
          { src: 'https://h5.ophyer.cn/official_website/other/wmyH5VRpanoramaWebsite5.png'}, 
        ]
      },
      {
        tabTitle: '配套服务',
        itemList:[
          { title: '导播间', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-1.png'},
          { title: '休息区', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-2.png'},
          { title: '会议室', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-3.png'},
          { title: '化妆间', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-4.png'},    
          { title: '摄像', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-5.png'},
          { title: '设计策划', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-6.png'},
          { title: '演员灯光', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-7.png'},
          { title: '影视后期', src: 'https://h5.ophyer.cn/official_website/other/ShootingServiceMatching-8.png'},  
        ]
      }
    ]
  }
}
// h5 大客户定制
const h5BigCustomer = {
  module: {
    title:'定制服务介绍',
    subtitle:'服务活动营销全链路，一体化赋能营销目标',
    itemList:[
      {
        title:"活动内容、 策划方案量身定制",
        icon: "https://h5.ophyer.cn/official_website/other/wmyH5BigCustomer1.png",
        explain: "结合客户营销目标与预算，灵活设计活动方案及功能，更符合您的营销诉求",
      },
      {
        title:"产品功能定制 全方位满足客户需求",
        icon: "https://h5.ophyer.cn/official_website/other/wmyH5BigCustomer2.png",
        explain: "支持定制开发、本地部署和API对接，致力于提供全渠道、多场景的营销服务",
      },
      {
        title:"一对一专属运营指导 活动过程全透明可追溯",
        icon: "https://h5.ophyer.cn/official_website/other/wmyH5BigCustomer3.png",
        explain: "签约后即安排一对一的大客户运营经理，全程参与活动运营，依据数据反馈和客户需求，随时调整运营方案",
      },
      {
        title:"活动结束后提供数据分析 报告，营销效果复盘",
        icon: "https://h5.ophyer.cn/official_website/other/wmyH5BigCustomer4.png",
        explain: "对活动结果即时复盘，对比客户预期，总结活动的内容策划、执行方案及奖品设计，支撑后续营销决策",
      },
    ]
  },
  module1: {
    title:'为什么选择我们',
    subtitle:'服务活动营销全链路，一体化赋能营销目标',
    itemList:[
      {
        title:"产品单独定制化",
        src: require('../../public/img/icons/customerHome/cus2-1.svg'),
        content: "支持定制开发、本地部署和API对接，致力于提供全渠道、多场景的营销服务",
      },
      {
        title:"一对一运营指导",
        src: require('../../public/img/icons/customerHome/cus2-2.svg'),
        content: "针对品牌方，提供一对一大客户专属运营指导；服务活动全周期",
      },
      {
        title:"资源共享联动营销",
        src: require('../../public/img/icons/customerHome/cus2-3.svg'),
        content: "付费大客户共享资源，联动营销，360°赋能品牌影响力，树立行业标杆",
      },
      {
        title:"7*24管家服务",
        src: require('../../public/img/icons/customerHome/cus2-4.svg'),
        content: "100+资深工程师7*24小时在线，全天候保障活动稳定",
      },
    ]
  },
  module2: {
    title:'应用场景',
    subtitle:'',
    itemList:[
      {
        title:"电商零售",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img1.png"
      },
      {
        title:"3C数码",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img2.png"
      },
      {
        title:"房产家居",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img3.png"
      },
      {
        title:"汽车",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img4.png"
      },
      {
        title:"智慧文旅",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img5.png"
      },
      {
        title:"智慧教育",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img6.png"
      },
      {
        title:"酒店民宿",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img7.png"
      },
      {
        title:"餐饮",
        src: "https://h5.ophyer.cn/official_website/other/saas-cj-img8.png"
      },
    ]
  },
}

export default{
    WanqingCloud,
    ShootingService,
    IntelligentCulturalTourism,
    WisdomEducation,
    InvestmentPromotion,
    ARHome,
    ARcase,
    ARSolution,
    customersSolution,
    customersCase,
    customersHome,
    AICase,
    h5SolutionAR,
    h5VRpanoramaWebsite,
    h5BigCustomer
}